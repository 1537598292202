.resume-container {
    margin-left: auto;
    margin-right: auto;
    width: min-content;
    padding-top: 10px;
}

.download-button {
    float: right;
    margin-bottom: 5px;
    margin-top: 5px;
}

.resume {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}