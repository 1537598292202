.header {
    width: 100%;
    height: 50px;
    background-color: #3d485e;
    position: fixed;
    top: 0;
    padding-bottom: 2px;
}

.nav-bar {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.nav-component {
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    height: 100%;
    text-decoration: none;
    border-bottom: 2px solid #3d485e;
}

.nav-component:hover {
    color: #4183ff;
}

.nav-component-active {
    color: #4183ff;
    border-bottom: 2px solid #4183ff;
}
