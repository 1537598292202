.footer {
    width: 100%;
    height: 50px;
    background-color: #3d485e;
}

.links {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}

.footer-link {
    display: flex;
    align-items: center;
    height: 100%;
    color:white;
    text-decoration: none;
    padding-left: 15px;
}
