.project-container {
    width: 100%;
    border-bottom: 2px solid black;
    padding: 10px;
}

.project-title {

}

.project-description {

}

.project-link {
    color: white;
    display:block;
}

.project-link-text {
    color: white;
}

.projects-container {
    width: 80%;
    margin-left: 10%;
    padding-bottom: 10px;
}

.back-button {
    color: white;
    margin-top: 5000px;
}
